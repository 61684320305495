<template>
  <div class="tab-content" id="PTtab-2" :key="'PTtab-2'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">하부파트너목록</h3>
      </div>
      <div class="PTsch">
        <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId"/>
        <a @click="getPartnerList(1)">
          <img src="@/assets/img/search.png" alt=""/>
        </a>
        <ul class="btBtnlist">
          <li @click="onChangePartnerLevel('')">전체</li>
          <template v-for="item in partnerLevelList" :key="item.code">
            <template v-if="item.code !== 'NORMAL'">
              <li @click="onChangePartnerLevel(item.code)">{{item.codeName}}</li>
            </template>
          </template>
        </ul>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="8%">
                <col width="9%">
                <col width="7%">
                <col width="12%">
                <col width="7%">
                <col width="7%">
                <col width="7%">
                <col width="11%">
                <col width="11%">
                <col width="8%">
                <col width="13%">
              </colgroup>
              <thead>
              <tr>
                <th>아이디</th>
                <th>닉네임</th>
                <th>파트너등급</th>
                <th>상위아이디/닉네임</th>
                <th>카지노요율</th>
                <th>호텔카지노요율</th>
                <th>슬롯요율</th>
                <th>보유금</th>
                <th>보유포인트</th>
                <th>하부 생성</th>
                <th>가입 일시</th>
              </tr>
              </thead>
              <tbody>
                <template v-if="partnerList.length > 0">
                  <tr v-for="item in partnerList" :key="item.memId">
                    <td :class="item.myLevel">{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td><span class="box" :class="item.myLevel">{{item.myLevelName}}</span></td>
                    <td>{{item.recommenderId}}/{{item.recommenderNick}}</td>
                    <td>{{item.casinoRate}}</td>
                    <td>{{item.hcasinoRate}}</td>
                    <td>{{item.slotRate}}</td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>{{thousand(item.pointAmt)}}</td>
                    <td><button class="saveBtnPT" v-if="item.myLevel !== 'PTN_5'" @click="onChangePartnerAddPopupSub(item)">생성</button></td>
                    <td>{{dateFormatAll(item.joinDate)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="9">내역 없음</td>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="partnerList.length > 0">
                 <ul v-for="item in partnerList" :key="item.memId">
                   <li :class="item.myLevel"><em>아이디</em>{{item.memId}}</li>
                   <li><em>닉네임</em>{{item.memNick}}</li>
                   <li><em>파트너등급</em><span class="box" :class="item.myLevel">{{item.myLevelName}}</span></li>
                   <li><em>상위아이디<br />/닉네임</em>{{item.recommenderId}}/{{item.recommenderNick}}</li>
                   <li><em>카지노요율</em>{{item.casinoRate}}</li>
                   <li><em>호텔카지노<br>{{item.hcasinoRate}}</em></li>
                   <li><em>슬롯요율</em>{{item.slotRate}}</li>
                   <li><em>보유금</em>{{thousand(item.cashAmt)}}</li>
                   <li><em>보유포인트</em>{{thousand(item.pointAmt)}}</li>
                   <li><em>가입 일시</em>{{dateFormatAll(item.joinDate)}}</li>
                 </ul>
               </template>
                <template v-else>
                 <ul>
                    <li>내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>

      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="getPartnerList"
      />
      <!------------------------------------------------------------------------------->
    </div>
    <div v-if="makesub" class="moveWrap2">
      <div class="tabmenu_make moveWraphead">
        <ul>
          <li @click="currentTab = 'makeTab1'">파트너</li>
          <!-- <li @click="currentTab = 'makeTab2'">회원</li> -->
        </ul>
        <a class="close" @click="onChangePartnerAddPopupSub"><img src="@/assets/img/icon_cancel.png" /></a>
      </div>
      <!-- <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab1'">
        <h4>파트너 생성</h4>
        <table class="part">
          <colgroup>
            <col width="20%"/>
            <col width="30%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>아이디</th>
            <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
            <th>비밀번호</th>
            <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td><input class="in" type="text" v-model="newPartner.memNick" /></td>
            <th>출금 비밀번호</th>
            <td><input class="in password" pattern="[0-9]*" type="number" v-model="newPartner.cashOutPass" /></td>
          </tr>
          <tr>
            <th>추천인 아이디</th>
            <td>{{newPartner.recommenderId}}({{newPartner.partnerLevelName}})</td>
            <th>계좌정보</th>
            <td class="accountInfo">
              <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
              <input class="in w45w" type="text" v-model="newPartner.bankAcc"/>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td><input class="in" type="text" v-model="newPartner.memName" /></td>
            <th>전화번호</th>
            <td><input class="in" type="text" v-model="newPartner.memPhone" /></td>
          </tr>
        </table>
        <h4>게임별 요율설정</h4>
        <table>
          <tr>
            <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
            <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
          </tr>
          <tr>
            <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              {{$t(`front.gnb.${key}`)}}
            </th>
            <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              {{$t(`front.gnb.${key}`)}}
            </th>
          </tr>
          <tr>
            <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
              <a class="rbnt">최대값 : {{item.maxPointRate}}</a><br>
              <a class="rbnt">최소값 : {{item.minPointRate}}</a>
            </td>
            <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
              <a class="rbnt">최대값 : {{item.maxLoseRate}}</a><br>
              <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
            </td>
          </tr>
          <tr>
            <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              <input type="text" v-model="item[`${key}PR`]">
            </td>
            <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              <input type="text" v-model="item[`${key}LR`]">
            </td>
          </tr>
        </table>
        <a class="btn" @click="onAddPartnerSubmit">등록</a>
      </div> -->

      <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab1'">
        <h2 style="padding:50px 150px;font-weight: bold;font-size:27px;">현재 개발 진행중입니다.</h2>
      </div>

      <!-- <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab2'">
        <h4>회원 생성</h4>
        <table class="part">
          <colgroup>
            <col width="20%"/>
            <col width="30%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>아이디</th>
            <td><input class="in" name="newMemId" type="text" autocomplete="off" role="presentation"/></td>
            <th>비밀번호</th>
            <td><input class="in" name="newMemPass" type="password" autocomplete="off"/></td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td><input class="in" type="text"/></td>
            <th>출금 비밀번호</th>
            <td><input class="in password" pattern="[0-9]*" type="number" /></td>
          </tr>
          <tr>
            <th>추천인 아이디</th>
            <td></td>
            <th>계좌정보</th>
            <td class="accountInfo">
              <input class="in" type="text"/>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td><input class="in" type="text"/></td>
            <th>전화번호</th>
            <td><input class="in" type="text"/></td>
          </tr>
        </table>
        <a class="btn" @click="onAddPartnerSubmit">등록</a>
      </div> -->
    </div>
    <!-- <div v-if="makesub" class="moveWrap2">
      <div class="moveWraphead">
        <h3>파트너 생성</h3>
        <a class="close" @click="onChangePartnerAddPopupSub"><img src="@/assets/img/icon_cancel.png" /></a>
      </div>
      <div class="moveWrapbody">
        <h4>파트너 정보</h4>
        <table class="part">
          <colgroup>
            <col width="20%"/>
            <col width="30%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>아이디</th>
            <td><input class="in" name="newMemId" type="text" autocomplete="off" role="presentation"/></td>
            <th>비밀번호</th>
            <td><input class="in" name="newMemPass" type="password" autocomplete="off"/></td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td><input class="in" type="text"/></td>
            <th>출금 비밀번호</th>
            <td><input class="in password" pattern="[0-9]*" type="number" /></td>
          </tr>
          <tr>
            <th>추천인 아이디</th>
            <td></td>
            <th>계좌정보</th>
            <td class="accountInfo">
              <input class="in" type="text"/>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td><input class="in" type="text"/></td>
            <th>전화번호</th>
            <td><input class="in" type="text"/></td>
          </tr>
        </table>
        <h4>게임별 요율설정</h4>
        <table>
          <tr>
            <th colspan="3">{{$t('front.stributor.roll')}}(%)</th>
            <th colspan="3">{{$t('front.stributor.lose')}}(%)</th>
          </tr>
          <tr>
            <td>카지노</td>
            <td>호텔카지노</td>
            <td>슬롯</td>
            <td>카지노</td>
            <td>호텔카지노</td>
            <td>슬롯</td>
          </tr>
          <tr>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
            <td>
              <a class="rbnt">최대값 : </a>
              <a class="rbnt">최소값 : </a>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text">
            </td>
            <td>
              <input type="text">
            </td>
            <td>
              <input type="text">
            </td>
            <td>
              <input type="text">
            </td>
            <td>
              <input type="text">
            </td>
            <td>
              <input type="text">
            </td>
          </tr>
        </table>
        <a class="btn">등록</a>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  partnerLevels, retailMyPartnerList, partnerJoin, partnerRates, getRetailSummary
} from '@/api/retail'
import Pagination from '@/components/ui/Pagination.vue'
import { isValidPasswordPartner, isValidUserId, thousand } from '@/libs/utils'
import { mapState } from 'vuex'
// import BankList from '@/components/ui/BankList.vue'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'

export default {
  name: 'PartnerList',
  components: {
    Pagination
    // BankList
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    partnerLevelName () {
      console.log(this.item.partnerLevel)
      return PARTNER_LEVEL_NAME[this.item.partnerLevel]
    }
  },
  data () {
    return {
      makesub: false,
      summary: {},
      rate: {},
      currentTab: 'makeTab1',
      searchType: 'P',
      searchPartnerLevel: '',
      partnerLevelList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null,
      searchMemId: '',
      myRate: {},
      myChildrenRate: [],
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      rateMaxMinList: [],
      newPartnerLevel: {}
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.getSummary()
    await this.getPartnerRateForNewPartner()
    await this.getPartnerList(1)
    this.newPartnerLevel = this.partnerLevelList[0]
    this.emitter.emit('Loading', false)
  },
  methods: {
    thousand,
    onChangePartnerAddPopupSub (currentPartner) {
      console.log(currentPartner)
      this.makesub = !this.makesub
      if (this.makesub) {
        console.log(this.rateMaxMinList)
        const list = this.rateMaxMinList

        this.newPartner.recommenderId = currentPartner.memId
        this.newPartner.partnerLevel = currentPartner.myLevel
        this.newPartner.partnerLevelName = currentPartner.myLevelName

        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          const groupCodeName = this.commonCodeByCode[vendorCode].groupCodeName
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode === 'casino' && groupCodeName.indexOf('HC-') > -1) {
            groupCode = 'hcasino'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
          console.log(groupCode)
        }
      }
    },
    getPartnerRateForNewPartner () {
      return partnerRates({}).then(res => {
        console.log('[RES]/api/retail/partnerRates : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          this.rateMaxMinList = list
        }
      })
    },
    onChangePartnerLevel (partnerLevel) {
      this.searchPartnerLevel = partnerLevel
      this.getPartnerList(1)
    },
    getPartnerList (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      const params = {
        page: page,
        searchMemId: this.searchMemId,
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType,
        count_per_list: 20
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      return retailMyPartnerList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.partnerList = result.data.list
          this.pageInfo = result.data.pageInfo
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          console.log(partnerObj)

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onAddPartnerSubmit () {
      const reqData = { ...this.newPartner, rateGroup: {} }

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        return false
      }

      if (!isValidUserId(reqData.memId)) {
        this.onAlert('warningart', 'api.U101')
        return false
      }

      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        return false
      }

      if (!isValidPasswordPartner(reqData.memPass) || reqData.memPass.length > 20) {
        this.onAlert('warningart', 'api.U102')
        return false
      }

      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        return false
      }

      return partnerJoin(reqData).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너 생성신청이 완료되었습니다. 관리자에서 승인되면 가입처리가 완료됩니다.')
          this.onChangePartnerAddPopupSub()
          this.newPartner = { ...this.defaultNewPartner }
          this.getPartnerList(1)
        } else {
          console.log(res)
          this.onAlert('warningart', result.resultMessage)
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    getSummary () {
      getRetailSummary({}).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          this.summary = data.data.summary
          this.summaryNew = data.data.summaryNew
        }
      })
    }
  }
}
</script>
<style scoped>
  .PTN_1 { color: #b10000; }
  .PTN_2 { color: #d86800; }
  .PTN_3 { color: #c4a600; }
  .PTN_4 { color: #00a53f; }
  .PTN_5 { color: #4668ff; }
  .NORMAL { color: #8f5fff; }
  .box {display: inline-block; }
  .PTN_1.box {background: #b10000;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .PTN_2.box {background: #d86800;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .PTN_3.box {background: #c4a600;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .PTN_4.box {background: #00a53f;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .PTN_5.box {background: #4668ff;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .NORMAL.box {background: #8f5fff;color: #fff; padding: 5px 4px;border-radius: 3px;}
  .datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;}
  .moveWrap2 {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'NanumSquareNeo';box-shadow: 0 0 10px 1px #ddd;}
  .moveWrap2 .close {position: absolute;right: 50px;top: 18px; cursor: pointer;}
  .moveWrap2 .close img {height: 35px;}
  .moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
  .moveWrapbody { padding: 0 50px 20px; }
  .moveWrap2 h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
  .moveWrap2 table {width: 800px;text-align: center;}
  .moveWrap2 th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
  .moveWrap2 table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
  .moveWrap2 .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
  .moveWrap2 td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
  .moveWrap2 input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
  .moveWrap2 input.in {width: 90%;height: 28px;}
  .moveWrap2 input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
  .moveWrap2 .accountInfo input.in { width: 54%; }
  .moveWrap2 .accountInfo input.in.w45w {width: 45%;}
  .moveWrap2 select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
  .moveWrap2 p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
  .moveWrap2 a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
  /* .tabmenu_make { width: } */
  .tabmenu_make > ul {display: flex; gap: 10px; }
  .tabmenu_make > ul li {border: 1px solid; border-radius: 10px; padding: 8px 15px; }
</style>
<style scoped src="@/styles/striNew.css"></style>
